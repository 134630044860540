import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "App";

import {
  Button,
  Dialog,
  Typography,
  Snackbar,
  Alert,
  Avatar,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { baseURL, endPoints, liveURL } from "constants/apiList";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";

export default function Stat() {
  const navigate = useNavigate();
  const params = useParams();
  let { type, days } = params;
  const { headers } = useContext(AppContext).data;

  const [data, setData] = useState(null);
  const [sum, setSum] = useState(null);

  const getData1 = async () => {
    await axios
      .post(
        `${liveURL}/challenge/statsDate`,
        { type: type, interval: days },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        const _users = res.data.result;
        const changedUsers = [];
        for (let i = 0; i < _users.length; i++) {
          if (_users[i].deactivate == 0) {
            changedUsers.push({
              ..._users[i],
              status: "Active",
              number: i + 1,
            });
          } else if (_users[i].deactivate == 1) {
            changedUsers.push({
              ..._users[i],
              status: "Inactive",
              number: i + 1,
            });
          }
        }

        // // to formate createdAt
        // const arrOfData = [];
        // for (let j = 0; j < changedUsers.length; j++) {
        //   arrOfData.push({...changedUsers[j], })
        //   endDate: res.data.result.archived[i].endDate.split("T")[0],

        // }
        setData(changedUsers);
        // console.log(changedUsers);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getData2 = async () => {
    await axios
      .post(
        `${liveURL}/challenge/statsDate`,
        { type: "content_videos", interval: days, revenue: true },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        const _users = res.data.result;
        const changedUsers = [];
        for (let i = 0; i < _users.length; i++) {
          if (_users[i].deactivate == 0) {
            changedUsers.push({
              ..._users[i],
              status: "Active",
              number: i + 1,
            });
          } else if (_users[i].deactivate == 1) {
            changedUsers.push({
              ..._users[i],
              status: "Deactive",
              number: i + 1,
            });
          }
        }
        setData([...changedUsers]);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    if (type == "revenue") {
      getData2();
    } else {
      getData1();
    }
  }, [type, days]);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type == "revenue" && data) {
      if (data.length > 0) {
        let _sum = 0;

        for (let i = 0; i < data.length; i++) {
          _sum = _sum + data[i].fee;
        }
        setSum(_sum);
      } else {
        setSum("zero");
      }
    }
  }, [data]);

  console.log(data);

  const optionsforUsers = {
    onRowClick: (user) => {
      // console.log(user);
      const id = user[1];
      navigate(`/users_section/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForUsers = [
    {
      name: "number",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="cicle"
              src={image}
              sx={{ width: "3rem", height: "3rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status) => {
          return (
            <Typography
              sx={{
                color: status == "Active" ? "green" : "red",
                fontWeight: "bolder",
              }}>
              {status}
            </Typography>
          );
        },
      },
    },
  ];

  const optionsforContent = {
    onRowClick: (video) => {
      // console.log(video);
      const link = video[4];
      navigate(`/video/video?=${link}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForContent = [
    {
      name: "number",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "thumbnail",
      label: "Video Thumbnail",
      options: {
        customBodyRender: (thumbnail) => {
          return (
            <Avatar
              variant="rounded"
              src={thumbnail}
              sx={{ width: "5rem", height: "5rem" }}
            />
          );
        },
      },
    },

    {
      name: "likes",
      label: "Video Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "video",
      label: "Challenge Video",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Uploaded on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  const optionsforRevenue = {
    onRowClick: (item) => {
      // console.log(item);
      const id = item[1];
      navigate(`/challenges/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForRevenue = [
    {
      name: "number",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "challengeId",
      label: "challengeId",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "thumbnail",
      label: "Video Thumbnail",
      options: {
        customBodyRender: (thumbnail) => {
          return (
            <Avatar
              variant="rounded"
              src={thumbnail}
              sx={{ width: "5rem", height: "5rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "video",
      label: "Challenge Video",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fee",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Paid on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  console.log(sum);

  if (type == "users") {
    return (
      <Layout title="Users">
        <div className="users">
          <div style={{ margin: "1rem 0" }}>
            <Typography variant="h4">Users in last {days} Days</Typography>
          </div>
          <div className="user__tableContainer">
            {data ? (
              <>
                {data.length > 0 ? (
                  <div className="table__mui">
                    <MUIDataTable
                      title={"Users"}
                      data={data}
                      columns={columnsForUsers}
                      options={optionsforUsers}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                    No data is available.
                  </Typography>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </Layout>
    );
  } else if (type == "content_videos") {
    return (
      <Layout title="Video Contents">
        <div style={{ margin: "1rem" }}>
          {days == "all" ? (
            <>
              <Typography variant="h4" sx={{ margin: "2rem 1rem 1rem 0" }}>
                Videos Uploaded till date
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4" sx={{ margin: "2rem 1rem 1rem 0" }}>
                Videos Uploaded in last {days} days
              </Typography>
            </>
          )}
          {data ? (
            <>
              {data.length > 0 ? (
                <div className="table__mui">
                  <MUIDataTable
                    title={"Video Contents"}
                    data={data}
                    columns={columnsForContent}
                    options={optionsforContent}
                  />
                </div>
              ) : (
                <Typography
                  variant="h5"
                  sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                  No data is available.
                </Typography>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Layout>
    );
  } else if (type == "revenue") {
    return (
      <Layout title="Revenue">
        <div style={{ margin: "1rem" }}>
          <div className="revenue__header"></div>
          {days == "all" ? (
            <>
              <Typography variant="h4" sx={{ margin: "2rem 1rem 1rem 0" }}>
                Revenue till date is $
                {sum ? (
                  <>{sum === "zero" ? 0 : sum}</>
                ) : (
                  <>
                    <CircularProgress
                      sx={{
                        width: "1rem",
                        height: "1rem",
                        position: "relative",
                        top: "10px",
                        marginLeft: "1rem",
                      }}
                    />
                  </>
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4" sx={{ margin: "2rem 1rem 1rem 0" }}>
                Revenue in last {days} days is $
                {sum ? (
                  <>{sum === "zero" ? 0 : sum}</>
                ) : (
                  <>
                    <CircularProgress
                      sx={{
                        width: "1rem",
                        height: "1rem",
                        position: "relative",
                        top: "10px",
                        marginLeft: "1rem",
                      }}
                    />
                  </>
                )}
              </Typography>
            </>
          )}

          {data ? (
            <>
              {data.length > 0 ? (
                <div className="table__mui">
                  <MUIDataTable
                    title={"Revenue"}
                    data={data}
                    columns={columnsForRevenue}
                    options={optionsforRevenue}
                  />
                </div>
              ) : (
                <Typography
                  variant="h5"
                  sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                  No data is available.
                </Typography>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Layout>
    );
  }
}
