import React, { useState, useContext, useEffect } from "react";
import "./layout.css";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "App";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import EventIcon from "@mui/icons-material/Event";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import GroupIcon from "@mui/icons-material/Group";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MessageIcon from "@mui/icons-material/Message";

import Header from "fragments/header/header";
import { COLORS } from "constants/colors";
import { Avatar, Typography } from "@mui/material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DashboardIcon from "@mui/icons-material/Dashboard";

const drawerWidth = 280;

function Layout({ window, children, title, description, keywords }) {
  const location = useLocation();

  const [showSideBar, setShowSideBar] = useState(false);
  const [user, setUser] = useState();

  const handleSideBar = () => {
    setShowSideBar(!showSideBar);
    // console.log(showSideBar);
  };

  // console.log(router.pathname.includes("docRegistrations") ? 'yes' : 'no');

  useEffect(() => {
    const _user = localStorage.getItem("user");

    if (_user) {
      const user = JSON.parse(_user);
      setUser(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawer = (
    <div>
      <div className="logoContainer">
        <div className="sideBarMenuIcon">
          <IconButton onClick={handleSideBar}>
            <MenuIcon sx={{ fontSize: "35px", color: "black" }} />
          </IconButton>
        </div>
        {/* <div className="logo">
          <img alt="logo" src="" />
        </div> */}
      </div>
      <div className="adminInfo">
        <Avatar sx={{ width: "70px", height: "70px" }} />
        <Typography>{user?.name}</Typography>
        <Typography>{user?.email}</Typography>
      </div>
      <List>
        <Link to="/dashboard" className="link">
          <ListItem
            // onMouseOver="this.sx.backgroundColor='grey' "
            button={location.pathname.includes("/dashboard") ? false : true}
            sx={
              location.pathname.includes("/dashboard")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <DashboardIcon
                sx={
                  location.pathname.includes("/dashboard")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">Dashboard</ListItemText>
          </ListItem>
        </Link>

        <Link to="/challenges" className="link">
          <ListItem
            // onMouseOver="this.sx.backgroundColor='grey' "
            button={location.pathname.includes("/challenges") ? false : true}
            sx={
              location.pathname.includes("/challenges")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <EventIcon
                sx={
                  location.pathname.includes("/challenges")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">Challenges</ListItemText>
          </ListItem>
        </Link>

        <Link to="/users_section" className="link">
          <ListItem
            button={location.pathname.includes("/users_section") ? false : true}
            sx={
              location.pathname.includes("/users_section")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <GroupIcon
                sx={
                  location.pathname.includes("/users_section")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">Users</ListItemText>
          </ListItem>
        </Link>

        <Link to="/chats" className="link">
          <ListItem
            button={location.pathname.includes("/chat") ? false : true}
            sx={
              location.pathname.includes("/chat")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <MessageIcon
                sx={
                  location.pathname.includes("/chat")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">Chats</ListItemText>
          </ListItem>
        </Link>

        <Link to="/reports" className="link">
          <ListItem
            button={location.pathname.includes("/reports") ? false : true}
            sx={
              location.pathname.includes("/reports")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <ReportGmailerrorredIcon
                sx={
                  location.pathname.includes("/reports")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">
              Reported Videos
            </ListItemText>
          </ListItem>
        </Link>

        <Link to="/custom_reports" className="link">
          <ListItem
            button={
              location.pathname.includes("/custom_reports") ? false : true
            }
            sx={
              location.pathname.includes("/custom_reports")
                ? {
                    backgroundColor: COLORS.black,
                    color: COLORS.white,
                    cursor: "pointer",
                  }
                : { cursor: "pointer" }
            }>
            <ListItemIcon>
              <QueryStatsIcon
                sx={
                  location.pathname.includes("/custom_reports")
                    ? {
                        color: COLORS.white,
                        cursor: "pointer",
                      }
                    : { cursor: "pointer", color: COLORS.black }
                }
              />
            </ListItemIcon>
            <ListItemText className="listItemText">
              Customized Reports
            </ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          href={location.pathname !== "/" && "/favicon.png"}
          type="image/x-icon"
        />
        <title>{title}</title>
        <meta name={description} content={keywords} />
      </Helmet>
      <Header onMenuClick={handleSideBar} />
      <Box
        className="drawer"
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={showSideBar}
          onClose={handleSideBar}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" className="main">
        <div>{children}</div>
      </Box>
    </>
  );
}

export default Layout;

Layout.defaultProps = {
  title: "Cashei Panel",
  description: "robots",
  keywords: "noindex, nofollow",
};
