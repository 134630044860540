export const COLORS = {
  primary: "#00ade9",
  secondary: "#ffbe00",
  white: "#ffffff",
  black: "#000000",
  primary_light: "#00afe991",
  linear_gradient: `linear-gradient(
    120deg,
    rgba(0, 173, 233, 1) 0%,
    rgba(113, 210, 243, 1) 79%,
    rgba(126, 214, 244, 1) 86%,
    rgba(155, 223, 246, 1) 92%,
    rgba(199, 237, 250, 1) 97%,
    rgba(255, 255, 255, 1) 100%
  )`,
};
