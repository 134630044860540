import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "App";

import {
  Button,
  Dialog,
  Typography,
  Snackbar,
  Alert,
  Avatar,
  TextField,
} from "@mui/material";
import axios from "axios";
import { baseURL, endPoints, liveURL } from "constants/apiList";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";

import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "utils/theme";
import { ThemeProvider } from "@mui/material";

export default function Report() {
  const navigate = useNavigate();
  const params = useParams();
  let { type, days } = params;
  const { headers } = useContext(AppContext).data;

  const [data, setData] = useState(null);
  const [sum, setSum] = useState(0);
  const [loading, setLoading] = useState(false);

  const [range, setRange] = useState({
    startDate: "",
    endDate: "",
  });

  const getUsers = async () => {
    setLoading(true);
    setData(null);
    await axios
      .post(
        `${liveURL}/admin/getReports`,
        { type: "users", startDate: range.startDate, endDate: range.endDate },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        const _users = res.data.result;
        const changedUsers = [];
        for (let i = 0; i < _users.length; i++) {
          if (_users[i].deactivate == 0) {
            changedUsers.push({
              ..._users[i],
              status: "Active",
              count: i + 1,
            });
          } else if (_users[i].deactivate == 1) {
            changedUsers.push({
              ..._users[i],
              status: "Deactive",
              count: i + 1,
            });
          }
        }
        setData(changedUsers);
      })
      .catch((err) => {
        // console.log(err);
      });
    setLoading(false);
  };

  const getContent = async () => {
    setLoading(true);
    setData(null);
    await axios
      .post(
        `${liveURL}/admin/getReports`,
        {
          type: "content_videos",
          startDate: range.startDate,
          endDate: range.endDate,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        const content = res.data.result;
        for (let i = 0; i < content.length; i++) {
          content[i]["count"] = i + 1;
        }
        setData([...content]);
      })
      .catch((err) => {
        // console.log(err);
      });
    setLoading(false);
  };

  const getRevenue = async () => {
    setLoading(true);
    setData(null);
    await axios
      .post(
        `${liveURL}/admin/getReports`,
        {
          type: "content_videos",
          startDate: range.startDate,
          endDate: range.endDate,
          revenue: true,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        const revenue = res.data.result;
        for (let i = 0; i < revenue.length; i++) {
          revenue[i]["count"] = i + 1;
        }
        setData([...revenue]);
      })
      .catch((err) => {
        // console.log(err);
      });
    setLoading(false);
  };

  // console.log(data);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type == "revenue" && data) {
      let _sum = 0;
      for (let i = 0; i < data.length; i++) {
        _sum = _sum + data[i].fee;
      }
      setSum(_sum);
    }
  }, [data]);

  const optionsforUsers = {
    onRowClick: (user) => {
      // console.log(user);
      const id = user[1];
      navigate(`/users_section/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForUsers = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="cicle"
              src={image}
              sx={{ width: "3rem", height: "3rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Account Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status) => {
          return (
            <Typography
              sx={{
                color: status == "Active" ? "green" : "red",
                fontWeight: "bolder",
              }}>
              {status}
            </Typography>
          );
        },
      },
    },
  ];

  const optionsforContent = {
    onRowClick: (video) => {
      // console.log(video[4]);
      const link = video[4];
      navigate(`/video/video?=${link}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForContent = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "thumbnail",
      label: "Video Thumbnail",
      options: {
        customBodyRender: (thumbnail) => {
          return (
            <Avatar
              variant="rounded"
              src={thumbnail}
              sx={{ width: "5rem", height: "5rem" }}
            />
          );
        },
      },
    },
    {
      name: "likes",
      label: "Video Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "video",
      label: "Challenge Video",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Uploaded on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  const optionsforRevenue = {
    onRowClick: (item) => {
      // console.log(item);
      const id = item[1];
      navigate(`/challenges/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columnsForRevenue = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "challengeId",
      label: "challengeId",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "thumbnail",
      label: "Video Thumbnail",
      options: {
        customBodyRender: (thumbnail) => {
          return (
            <Avatar
              variant="rounded"
              src={thumbnail}
              sx={{ width: "5rem", height: "5rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "video",
      label: "Challenge Video",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fee",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Paid on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  // console.log(data);

  const handleChange = (field, e) => {
    if (field === "startDate") {
      const dt = String(e.format()).split("T")[0];
      setRange({ ...range, startDate: dt });
    } else if (field === "endDate") {
      const dt = String(e.format()).split("T")[0];
      setRange({ ...range, endDate: dt });
    }
  };

  if (type == "users") {
    return (
      <Layout title="Users">
        <div className="users">
          <Typography variant="h4" sx={{ margin: "2rem 0" }}>
            Users
          </Typography>
          <div style={{ margin: "1rem 0" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <DatePicker
                    size="small"
                    disableFuture
                    label="Start Date"
                    views={["year", "month", "day"]}
                    value={range.startDate}
                    onChange={(e) => handleChange("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // sx={{ color: "black" }}
                        InputLabelProps={{
                          shrink: true,
                          disableUnderline: true,
                        }}
                        error={false}
                        // size="small"
                      />
                    )}
                  />
                  <DatePicker
                    size="small"
                    disableFuture
                    label="End Date"
                    views={["year", "month", "day"]}
                    value={range.endDate}
                    onChange={(e) => handleChange("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // sx={{ color: "black" }}
                        InputLabelProps={{
                          shrink: true,
                          disableUnderline: true,
                        }}
                        error={false}
                        // size="small"
                      />
                    )}
                  />
                </ThemeProvider>
              </LocalizationProvider>
              <Button onClick={() => getUsers()} variant="contained">
                Get Users
              </Button>
            </div>
          </div>
          <div className="user__tableContainer">
            {data ? (
              <>
                {data.length > 0 ? (
                  <div className="table__mui">
                    <MUIDataTable
                      title="Users"
                      data={data}
                      columns={columnsForUsers}
                      options={optionsforUsers}
                    />
                  </div>
                ) : (
                  <>
                    {range.startDate && range.endDate ? (
                      <>
                        <Typography
                          variant="h5"
                          sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                          No data is available.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h5"
                          sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                          Dates are not selected. Try Again
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <div style={{ margin: "2rem 0" }}>
                    <Typography
                      variant="h5"
                      sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                      Select the date range and get the result.
                    </Typography>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  } else if (type == "content_videos") {
    return (
      <Layout title="Video Contents">
        <Typography variant="h4" sx={{ margin: "2rem 1rem" }}>
          Video Contents
        </Typography>
        <div style={{ margin: "1rem" }}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  size="small"
                  disableFuture
                  label="Start Date"
                  views={["year", "month", "day"]}
                  value={range.startDate}
                  onChange={(e) => handleChange("startDate", e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // sx={{ color: "black" }}
                      InputLabelProps={{
                        shrink: true,
                        disableUnderline: true,
                      }}
                      error={false}
                      // size="small"
                    />
                  )}
                />
                <DatePicker
                  size="small"
                  disableFuture
                  label="End Date"
                  views={["year", "month", "day"]}
                  value={range.endDate}
                  onChange={(e) => handleChange("endDate", e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // sx={{ color: "black" }}
                      InputLabelProps={{
                        shrink: true,
                        disableUnderline: true,
                      }}
                      error={false}
                      // size="small"
                    />
                  )}
                />
              </ThemeProvider>
            </LocalizationProvider>
            <Button onClick={() => getContent()} variant="contained">
              Get Contents
            </Button>
          </div>
        </div>
        {data ? (
          <>
            {data.length > 0 ? (
              <div className="table__mui" style={{ margin: "1rem" }}>
                <MUIDataTable
                  title={"Video Contents"}
                  data={data}
                  columns={columnsForContent}
                  options={optionsforContent}
                />
              </div>
            ) : (
              <Typography
                variant="h5"
                sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                No data is available.
              </Typography>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <div style={{ margin: "2rem 1rem" }}>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                  Select the date range and get the result.
                </Typography>
              </div>
            )}
          </>
        )}
      </Layout>
    );
  } else if (type == "revenue") {
    return (
      <Layout title="Revenue">
        <div>
          <Typography variant="h4" sx={{ margin: "2rem 1rem" }}>
            Revenue is ${sum}
          </Typography>
          <div style={{ margin: "1rem" }}>
            <div style={{ display: "flex", gap: "1rem" }}>
              {/* <TextField
                type="date"
                label="Start Date"
                onChange={(e) =>
                  setRange({ ...range, startDate: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <DatePicker
                    size="small"
                    disableFuture
                    label="Start Date"
                    views={["year", "month", "day"]}
                    value={range.startDate}
                    onChange={(e) => handleChange("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // sx={{ color: "black" }}
                        InputLabelProps={{
                          shrink: true,
                          disableUnderline: true,
                        }}
                        error={false}
                        // size="small"
                      />
                    )}
                  />
                  <DatePicker
                    size="small"
                    disableFuture
                    label="End Date"
                    views={["year", "month", "day"]}
                    value={range.endDate}
                    onChange={(e) => handleChange("endDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // sx={{ color: "black" }}
                        InputLabelProps={{
                          shrink: true,
                          disableUnderline: true,
                        }}
                        error={false}
                        // size="small"
                      />
                    )}
                  />
                </ThemeProvider>
              </LocalizationProvider>
              {/* <TextField
                type="date"
                label="End Date"
                onChange={(e) =>
                  setRange({ ...range, endDate: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <Button onClick={() => getRevenue()} variant="contained">
                Get Revenue
              </Button>
            </div>
          </div>
          {data ? (
            <>
              {data.length > 0 ? (
                <div className="table__mui">
                  <MUIDataTable
                    title={"Revenue"}
                    data={data}
                    columns={columnsForRevenue}
                    options={optionsforRevenue}
                  />
                </div>
              ) : (
                <Typography
                  variant="h5"
                  sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                  No data is available.
                </Typography>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
                <div style={{ margin: "2rem 1rem" }}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                    Select the date range and get the result.
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }
}
