import React, { useEffect, useState, useRef, useContext } from "react";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "fragments/layout/layout";

// firebase
import {
  addDoc,
  doc,
  setDoc,
  collection,
  serverTimestamp,
  getDoc,
  where,
  getDocs,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import { IconButton, TextField, Typography } from "@mui/material";
import { SendOutlined } from "@mui/icons-material";
import Loader from "components/loader/loader";
import { liveURL } from "constants/apiList";
import { endPoints } from "../../../constants/apiList";
import axios from "axios";
import { AppContext } from "../../../App";

export default function Chat() {
  const messageEl = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { id, name } = params;
  const { headers } = useContext(AppContext).data;

  const profileLink = window.location.search.split("=")[1];

  const [roomId, setRoomId] = useState();
  const [msgs, setMsgs] = useState();
  const [msg, setMsg] = useState();
  const [msgSent, setMsgSent] = useState(false);

  const [room, setRoom] = useState({
    users: [String(id), "000"],
    lastMessage: "",
    user1: {
      id: String(id),
      name: String(name),
      image: String(profileLink),
    },
    user2: {
      id: "000",
      name: "Admin",
      image:
        "https://res.cloudinary.com/duyzfmbrr/image/upload/v1666093585/profile_images/profile_agymhr.png",
    },
    timestamp: serverTimestamp(),
  });

  // console.log(room);

  const checkFirebase = async () => {
    const dbRef = collection(db, "chats");
    const arrOfData = [];

    const q = query(dbRef, where("users", "array-contains", id.toString()));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.data());
      arrOfData.push(doc.id);
    });

    // console.log(querySnapshot.docs.length);

    if (querySnapshot.docs.length === 0) {
      // console.log("if");
      await addDoc(dbRef, room)
        .then((res) => {
          // console.log("if");
          // console.log(res.id);
          setRoomId(res.id);
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (querySnapshot.docs.length !== 0) {
      setRoomId(arrOfData[0]);
    }
  };

  const readMsgs = async () => {
    const dbRef = collection(db, "chats", roomId, "messages");
    let arrOfData = [];

    const q = query(dbRef, orderBy("timestamp"));

    const unSub = onSnapshot(q, (snapchot) => {
      arrOfData = [];
      snapchot.docs.map((doc) => {
        arrOfData.push(doc.data());
      });
      setMsgs(arrOfData);
    });
  };

  const sendMsg = async () => {
    const dbRef = collection(db, "chats", roomId, "messages");

    if (msg.length > 0) {
      await addDoc(dbRef, {
        from: "000",
        to: id.toString(),
        name: "Admin",
        message: msg,
        timestamp: serverTimestamp(),
      })
        .then(async (res) => {
          const dbRef1 = doc(db, "chats", roomId);
          await updateDoc(dbRef1, {
            lastMessage: msg,
          })
            .then(async (res1) => {
              // console.log(res1);
              setMsg("");
              if (!msgSent) {
                await axios
                  .post(
                    `${liveURL}${endPoints.sendNotifications}`,
                    {
                      userId: parseInt(id),
                    },
                    { headers: headers }
                  )
                  .then((res) => {
                    // console.log(res);
                    setMsgSent(true);
                  })
                  .catch((err) => {
                    // console.log(err);
                  });
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    scrollToBottom();
  };

  const convertTime = (time) => {
    if (time) {
      const fireBaseTime = new Date(
        time.seconds * 1000 + time.nanoseconds / 1000000
      );
      const date = fireBaseTime.toDateString();
      const atTime = fireBaseTime.toTimeString();

      // console.log(`${atTime.split(":")[0]}:${atTime.split(":")[1]}`);

      // return `${atTime.split(":")[0]}:${atTime.split(":")[1] || ""} ${
      //   atTime.split("T")[1] || ""
      // }`;

      return `${atTime.split(":")[0]}:${atTime.split(":")[1]}`;
    } else {
      return "sending...";
    }
  };

  // console.log(roomId);
  // console.log(msgs);

  const scrollToBottom = () => {
    messageEl.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateSeen = async () => {
    const dbRef1 = doc(db, "chats", roomId);
    await updateDoc(dbRef1, {
      new: false,
    })
      .then((res1) => {
        // console.log(res1);
        setMsg("");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    scrollToBottom();
    updateSeen();
  }, [msgs]);

  useEffect(() => {
    if (roomId) {
      readMsgs();
    }
  }, [roomId]);

  useEffect(() => {
    if (name && id && profileLink) {
      checkFirebase();
    }
  }, [name, id, profileLink]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={`Chat with ${name}`}>
      <div className="chatPage">
        <div className="chat__header">
          <Typography variant="h4">Chat with</Typography>
          <Typography
            variant="h4"
            sx={{
              maxWidth: "16rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}>
            {name}
          </Typography>
        </div>
        {msgs ? (
          <>
            <div className="msgsContainer">
              {msgs.length > 0 ? (
                <div className="msgs">
                  {msgs.map((msg, i) => (
                    <div
                      className={
                        msg.name == "Admin"
                          ? "sentContainer"
                          : "receivedContainer"
                      }
                      key={i}>
                      <div
                        className={msg.name == "Admin" ? "sent" : "received"}>
                        <h5>{msg.name}</h5>
                        <p>{msg.message}</p>
                        <p
                          style={{
                            fontSize: "12px",
                            width: "100%",
                            textAlign: "right",
                            opacity: "0.4",
                          }}>
                          {convertTime(msg.timestamp)}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div ref={messageEl} />
                </div>
              ) : (
                <Typography variant="h5" sx={{ color: "red" }}>
                  No Messages yet
                </Typography>
              )}
            </div>
            <div className="msg__field">
              <TextField
                placeholder="Message"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                fullWidth
              />
              <div className="send__btn">
                <IconButton sx={{ color: "black" }} onClick={() => sendMsg()}>
                  <SendOutlined sx={{ fontSize: "2rem" }} />
                </IconButton>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  );
}
