import React, { useState, useEffect } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  TextField,
  Skeleton,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL, endPoints } from "constants/apiList";
import { LoadingButton } from "@mui/lab";

export default function Winners() {
  const navigate = useNavigate();
  let headers = {
    // Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("token")})`,
  };

  const [open, setOpen] = useState(false);
  const [winners, setWinners] = useState();
  const [allWinners, setAllWinners] = useState();
  const [alert, setAlert] = useState(false);
  const [selected, setSelected] = useState(false);

  const getWinners = async () => {
    // console.log(headers);
    await axios
      .get(`${baseURL}${endPoints.winner}`)
      .then((res) => {
        // console.log(res);
        if (res.data.result.length > 0) {
          setWinners(res.data.result);
          setAllWinners(res.data.result);
        } else {
          setWinners([]);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSearch = (query) => {
    if (!query) {
      setWinners(allWinners);
    } else {
      const filteredWinners = allWinners.filter((winner) => {
        const name = winner.userDetail[0].username.toLowerCase();
        return name.includes(query);
      });
      setWinners(filteredWinners);
    }
  };

  useEffect(() => {
    getWinners();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(winners);

  return (
    <Layout title="Winners">
      <>
        <div className="winners">
          <div className="winners__header">
            <Typography variant="h5">Winners</Typography>
            {/* <Button
              onClick={() => setOpen(true)}
              variant="contained"
              disableElevation>
              Add Challenge
            </Button> */}
          </div>
          <div style={{ width: "100%", display: "flex", marginBottom: "1rem" }}>
            <TextField
              label="Search"
              onChange={(e) => handleSearch(e.target.value)}
              size="small"
            />
          </div>
          <div className="tableContainer">
            {winners ? (
              <>
                {winners.length > 0 ? (
                  <div className="table">
                    <div className="table__header">
                      <Typography variant="h6">Winner Name</Typography>
                      <Typography variant="h6">Challenge Name</Typography>
                      <Typography variant="h6">Join Fee</Typography>
                      <Typography variant="h6">Actions</Typography>
                    </div>
                    <div className="table__body">
                      {winners.map((winner) => (
                        <>
                          <Divider />
                          <div className="table__row">
                            <Typography>
                              {winner.userDetail[0].username}
                            </Typography>
                            <Typography>{winner.title}</Typography>
                            <Typography>${winner.joinChallengeFee}</Typography>
                            <div className="winner__actions">
                              {/* <Link to={`/challenges/${winner._id}`}> */}
                              <IconButton onClick={() => setSelected(winner)}>
                                <EditIcon />
                              </IconButton>
                              {/* </Link> */}
                              <IconButton>
                                <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Typography>No Winners Available</Typography>
                )}
              </>
            ) : (
              <div className="table">
                <div className="table__header">
                  <Typography variant="h6">Name</Typography>
                  <Typography variant="h6">Fee</Typography>
                  <Typography variant="h6">Reward</Typography>
                  <Typography variant="h6">Actions</Typography>
                </div>
                <div className="table__body">
                  {Array(5)
                    .fill()
                    .map((i) => (
                      <>
                        <Divider />
                        <div className="table__row">
                          <Skeleton
                            variant="text"
                            width="6rem"
                            height="1.5rem"
                          />
                          <Skeleton
                            variant="text"
                            width="6rem"
                            height="1.5rem"
                          />
                          <Skeleton
                            variant="text"
                            width="6rem"
                            height="1.5rem"
                          />
                          <div className="challenge__actions">
                            <IconButton disabled>
                              <EditIcon />
                            </IconButton>
                            <IconButton disabled>
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {selected && (
          <Dialog open={selected} onClose={() => setSelected(null)}>
            <div className="createChallenge">
              <div className="challenge__header">
                <Typography variant="h5">Challenge Details</Typography>
              </div>
              <div className="challenge__form">
                <div className="form__1">
                  <TextField
                    label="Title"
                    value={selected?.title}
                    disabled
                    size="small"
                  />
                  <TextField
                    label="Challenge Participation Fee"
                    value={selected?.joinChallengeFee}
                    disabled
                    type="number"
                    size="small"
                  />
                  <TextField
                    label="challenge Reward"
                    value={selected?.joinChallengeFee}
                    disabled
                    type="number"
                    size="small"
                  />
                  <TextField
                    label="Challenge Slot"
                    type="date"
                    size="small"
                    disabled
                    value={selected?.createdAt.split("T")[0]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </div>
                <div className="form__2">
                  <Typography sx={{ width: "100%" }}>
                    Challenge Video:
                  </Typography>
                  {selected?.video && (
                    <video
                      id="my-player"
                      class="winner__videojs"
                      controls
                      preload="auto">
                      <source src={selected?.video}></source>
                      <p class="vjs-no-js">
                        To view this video please enable JavaScript, and
                        consider upgrading to a web browser that
                        <a
                          href="https://videojs.com/html5-video-support/"
                          target="_blank"
                          rel="noreferrer">
                          supports HTML5 video
                        </a>
                      </p>
                    </video>
                  )}
                </div>
                <div className="challenge__moreInfo">
                  <Typography variant="h6">Likes: {selected.like}</Typography>
                  <Typography variant="h6">
                    DisLikes: {selected.disLike}
                  </Typography>
                </div>
                <div className="form__3">
                  <TextField
                    value={selected?.description}
                    disabled
                    label="Description"
                    rows="3"
                    multiline
                    fullWidth
                  />
                </div>
              </div>
              <div className="challenge__header" style={{ marginTop: "1rem" }}>
                <Typography variant="h5">Winner Details</Typography>
              </div>
              <div className="challenge__form">
                <div className="form__1">
                  <TextField
                    label="Name"
                    value={selected?.userDetail[0].username}
                    disabled
                    size="small"
                  />
                  <TextField
                    label="Name"
                    value={selected?.userDetail[0].email}
                    disabled
                    size="small"
                  />
                </div>
              </div>
              <div className="createChallenge__btn">
                <LoadingButton
                  // loading={uploading}
                  // onClick={(e) => handleSubmit(e)}
                  variant="contained"
                  disableElevation>
                  Pay Reward
                </LoadingButton>
              </div>
            </div>
          </Dialog>
        )}
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
}
