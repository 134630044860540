import React, { useState, useEffect, useContext } from "react";
import "./index.css";

import { Typography } from "@mui/material";
import Layout from "fragments/layout/layout";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "App";
import { Person } from "@mui/icons-material";

import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

function CustomReports() {
  const navigate = useNavigate();
  // const { headers } = useContext(AppContext).data;
  const headers = localStorage.getItem("token");

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Custom Reports">
      <Typography variant="h4" sx={{ marginLeft: "1rem", marginTop: "2rem" }}>
        Custom Reports
      </Typography>
      <div className="reportContainer">
        <div className="reports">
          <Link to={`/custom_reports/users`} className="report1">
            <div className="report__icon">
              <Person />
            </div>
            <Typography sx={{ opacity: "0.6" }}>Users</Typography>
          </Link>
          <Link to={`/custom_reports/content_videos`} className="report2">
            <div className="report__icon">
              <VideoCameraBackIcon />
            </div>
            <Typography sx={{ opacity: "0.6" }}>Video Contents</Typography>
          </Link>
          <Link to={`/custom_reports/revenue`} className="report3">
            <div className="report__icon">
              <MonetizationOnIcon />
            </div>
            <Typography sx={{ opacity: "0.6" }}>Revenue</Typography>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default CustomReports;
