import React, { useContext } from "react";
import "./header.css";
import { AppContext } from "App";
import { Link, useLocation, useNavigate } from "react-router-dom";

// constants
import { COLORS } from "constants/colors";

// mui
import { IconButton, Divider, Typography, Button } from "@mui/material";
import { theme } from "utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import { removeCookie } from "../../utils/cookies";
import KeyIcon from "@mui/icons-material/Key";

export default function Header({ onMenuClick, clicked }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("notToken");
    window.location.reload();
  };

  return (
    <>
      <header className="header">
        <div className="menuIcon" onClick={() => onMenuClick()}>
          <IconButton>
            <MenuIcon sx={{ fontSize: "35px", color: "black" }} />
          </IconButton>
        </div>
        <div className="header__icons">
          {/* <Link to="/notifications">
            <IconButton>
              <NotificationsIcon />
            </IconButton>
          </Link> */}
          <Link to="/change_password">
            <IconButton>
              <KeyIcon />
            </IconButton>
          </Link>
          <ThemeProvider theme={theme}>
            <Button
              onClick={() => handleLogout()}
              disableElevation
              sx={{ height: "30px", marginRight: "15px" }}
              variant="contained"
              size="small">
              LogOut
            </Button>
          </ThemeProvider>
        </div>
      </header>
    </>
  );
}

const muiStyles = {
  text: {
    color: COLORS.primary,
    whiteSpace: "noWrap",
  },
  clickable: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: COLORS.primary,
    fontSize: "13px",
  },
  icon: {
    fontSize: "20px",
    opacity: "0.4",
  },
  menuIcon: {
    color: COLORS.primary,
    fontSize: "35px",
    // marginRight: "30px",
  },
};
