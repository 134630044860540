import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBJmrh1y2orOSx9QjhU9Ay0tgq49Kxol3U",
  authDomain: "cashei-app.firebaseapp.com",
  projectId: "cashei-app",
  storageBucket: "cashei-app.appspot.com",
  messagingSenderId: "874488284557",
  appId: "1:874488284557:web:639ff0cb8747c06065a9c4",
  measurementId: "G-N3FHYVLB60",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const fcm = getMessaging(app);
