import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate } from "react-router-dom";
import { AppContext } from "App";

import {
  Button,
  Dialog,
  Typography,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { baseURL, endPoints, liveURL } from "constants/apiList";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";

export default function Users() {
  const navigate = useNavigate();
  const { headers } = useContext(AppContext).data;

  const [users, setUsers] = useState(null);
  const [userToDel, setUserToDel] = useState(null);
  const [alert, setAlert] = useState(false);
  const [allUsers, setAllUsers] = useState(null);

  const getUsers = async () => {
    await axios
      .get(`${liveURL}${endPoints.getUsers}`, { headers: headers })
      .then((res) => {
        // console.log(res);
        const _users = res.data.result;
        const changedUsers = [];
        for (let i = 0; i < _users.length; i++) {
          if (_users[i].deactivate == 0) {
            changedUsers.push({
              ..._users[i],
              status: "Active",
              number: i + 1,
            });
          } else if (_users[i].deactivate == 1) {
            changedUsers.push({
              ..._users[i],
              status: "Inactive",
              number: i + 1,
            });
          }
        }
        setUsers(changedUsers);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleDelete = async (id) => {
    await axios
      .delete(`${baseURL}${endPoints.delUsers}/${id}`)
      .then((res) => {
        // console.log(res);
        setAlert({ type: "success", msg: "User Deleted." });
        getUsers();
        setUserToDel(false);
      })
      .catch((err) => {
        // console.log(err);
        setAlert({ type: "success", msg: "Something Went Wrong." });
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    onRowClick: (user) => {
      // console.log(user);
      const id = user[1];
      navigate(`/users_section/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: false,
  };

  const columns = [
    {
      name: "number",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: "exclude",
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="cicle"
              src={image}
              sx={{ width: "3rem", height: "3rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "status",
      label: "Account Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (status) => {
          return (
            <Typography
              sx={{
                color: status == "Active" ? "green" : "red",
                fontWeight: "bolder",
              }}>
              {status}
            </Typography>
          );
        },
      },
    },
  ];

  // console.log(users);

  return (
    <Layout title="Users">
      <>
        <div className="users">
          <div style={{ margin: "1rem 0" }}>
            <Typography variant="h4">Users</Typography>
          </div>
          <div className="user__tableContainer">
            {users ? (
              <>
                {users.length > 0 ? (
                  <div className="table__mui">
                    <MUIDataTable
                      title={"Users"}
                      data={users}
                      columns={columns}
                      options={options}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                    No data is available.
                  </Typography>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <Dialog open={userToDel} onClose={() => setUserToDel(false)}>
          <div className="editUser">
            <div className="user__header">
              <Typography variant="h6">
                Are You Sure! You want to delete that user!
              </Typography>
            </div>
            <div className="confirm__form__btn">
              <Button
                onClick={() => setUserToDel(false)}
                variant="contained"
                disableElevation>
                Cancel
              </Button>
              <Button
                onClick={() => handleDelete(userToDel)}
                sx={{ color: "red" }}
                variant="outlined"
                disableElevation>
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
}
