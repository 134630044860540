import Layout from "fragments/layout/layout";
import React from "react";

function Play() {
  const date = new Date();

  return (
    <Layout>
      <div></div>
      <div></div>
      <div></div>
      <div>
        <h1>{date.toString()}</h1>
      </div>
    </Layout>
  );
}

export default Play;
