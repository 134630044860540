import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import "./index.css";

import {
  Stack,
  FormControlLabel,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton,
  TextField,
  Radio,
  Typography,
  RadioGroup,
  Alert,
  Box,
  Snackbar,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import Layout from "fragments/layout/layout";
import axios from "axios";
import { baseURL, endPoints } from "constants/apiList";
import { liveURL } from "../../constants/apiList";
import { removeCookie } from "utils/cookies";

function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [alert, setAlert] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = async () => {
    if (
      credentials.newPassword.length >= 6 &&
      credentials.confirmPassword.length >= 6
    ) {
      if (credentials.newPassword === credentials.confirmPassword) {
        // console.log(credentials);
        setLoading(true);
        setError(false);
        await axios
          .post(`${liveURL}/admin/changePassword`, {
            email: credentials.email,
            password: credentials.newPassword,
          })
          .then((res) => {
            // console.log(res);
            setAlert({ type: "success", msg: res.data.message });
          })
          .catch((err) => {
            // console.log(err);
            setAlert({
              type: "error",
              msg: "Something Went Wrong. Try Again Later.",
            });
          });
        setLoading(false);
        // navigate("/challenges");
        handleLogout();
      } else {
        setError(`Both Posswords should be same`);
      }
    } else {
      setError(`Password length should be of atleast 6.`);
    }
  };

  const handleChange = (prop) => (event) => {
    setError(false);
    setCredentials({ ...credentials, [prop]: event.target.value.trim() });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogout = () => {
    removeCookie("user");
    removeCookie("token");
    removeCookie("notToken");
    window.location.reload();
  };

  // console.log(credentials);

  useEffect(() => {
    const _user = localStorage.getItem("user");

    if (!_user) {
      navigate("/", { replace: true });
    } else {
      const user = JSON.parse(_user);
      // console.log(user);
      setCredentials({ ...credentials, email: user.email });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Change Password">
      <>
        <div className="adminLogin">
          <Typography variant="h4" sx={{ margin: "10px" }}>
            Change Password
          </Typography>
          <div className="loginForm">
            {/* <TextField
              onChange={handleChange("email")}
              id="email"
              label="Email"
              variant="outlined"
              value={credentials.email || ""}
            /> */}
            <FormControl>
              <InputLabel htmlFor="newPassword">New Password</InputLabel>
              <OutlinedInput
                onChange={handleChange("newPassword")}
                id="newPassword"
                value={credentials.newPassword || ""}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="confirmPassword">
                Confrim Password
              </InputLabel>
              <OutlinedInput
                onChange={handleChange("confirmPassword")}
                id="confirmPassword"
                value={credentials.confirmPassword || ""}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            {error && (
              <div className={error ? "error" : "notError"}>
                <Alert severity="error">{error}</Alert>
              </div>
            )}
            <LoadingButton
              size="large"
              loading={loading}
              variant="contained"
              onClick={handleSignup}>
              Change Password
            </LoadingButton>
            {/* <Typography variant="caption" sx={{ opacity: "0.7" }}>
              or
            </Typography> */}
            {/* <div>
              <Link to="/" className="link">
                <Button>Log-In</Button>
              </Link>
            </div> */}
          </div>
        </div>
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
}

export default ChangePassword;
