import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL, endPoints, liveURL } from "constants/apiList";
import {
  TextField,
  Typography,
  Button,
  IconButton,
  Dialog,
  Avatar,
  Snackbar,
  Alert,
  InputAdornment,
  FormControl,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { AppContext } from "App";
import Loader from "components/loader/loader";
import moment from "moment";

export default function ChallengeInfo() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { headers } = useContext(AppContext).data;

  const [challenge, setChallenge] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [winner, setWinner] = useState(null);
  const [allowDel, setAllowDel] = useState(false);
  const [paying, setPaying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [alert, setAlert] = useState(false);
  const [allowChange, setAllowChange] = useState(false);
  const [reward, setReward] = useState(0);

  const [endDateOfSlot, setEndDateOfSlot] = useState();
  const [currentDate, setCurrentDate] = useState();

  const [confirm, setConfirm] = useState(false);

  const getChallenge = async () => {
    // console.log(headers);
    await axios
      .get(`${liveURL}${endPoints.getChallengeDetails}?challengeId=${id}`)
      .then((res) => {
        // console.log(res);

        const _participants = res.data.result.participent;
        for (let i = 0; i < _participants.length; i++) {
          for (let j = 0; j < _participants[i].length; j++) {
            _participants[i][j]["count"] = j + 1;
            // console.log(_participants[i][j]);
          }
        }
        // console.log(_participants);

        setChallenge({ ...res.data.result, participent: _participants });
      })
      .catch((err) => {
        // console.log(err);
        return;
      });
  };

  const handleChange = (field, e) => {
    setChallenge({
      ...challenge,
      details: { ...challenge.details, [field]: e.target.value },
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await axios
      .post(`${liveURL}/challenge/update`, {
        challengeId: parseInt(id),
        title: challenge.details.title,
        fee: challenge.details.fee,
        description: challenge.details.description,
      })
      .then((res) => {
        // console.log(res);
        setAlert({ type: "success", msg: "Challenge updated successfully." });
      })
      .catch((err) => {
        // console.log(err);
        setAlert({ type: "error", msg: "Something Went Wrong." });
      });
    setLoading(false);
  };

  const handleShowWinner = async (slot, i) => {
    calculateReward(i);
    setOpen(true);

    const nowUTC = new Date();
    const endUTC = new Date(slot.end);

    // nowUTC.setHours(nowUTC.getHours() - 5);
    // console.log("nowUTC`", nowUTC);
    // console.log("endUTC", endUTC);

    // console.log(nowUTC > endUTC);
    const _nowUTC = moment(nowUTC).utc();
    const _endUTC = moment(endUTC);

    // console.log("nowUTC", _nowUTC.format("DD-MM-YYYYThh:mm:ss"));
    // console.log("endUTC", _endUTC.format("DD-MM-YYYYThh:mm:ss"));

    // console.log(_nowUTC.isAfter(_endUTC));

    setEndDateOfSlot(_endUTC.format("DD-MM-YYYYThh:mm:ss"));
    setCurrentDate(_nowUTC.format("DD-MM-YYYYThh:mm:ss"));

    if (_nowUTC.isAfter(_endUTC)) {
      if (nowUTC > endUTC) {
        await axios
          .post(
            `${liveURL}${endPoints.getWinnerDetails}`,
            {
              challengeId: parseInt(id),
              startDate: slot.start,
              endDate: slot.end,
            },
            { headers: headers }
          )
          .then((res) => {
            // console.log(res);
            if (res.data.result.length > 0) {
              setWinner(res.data.result[0]);
            } else {
              setWinner("empty");
            }
          })
          .catch((err) => {
            // console.log(err);
            setAlert({
              type: "error",
              msg: "Something went wrong.",
            });
            setOpen(false);
          });
      } else {
        setWinner("empty");
      }
    } else {
      setWinner("empty");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setWinner(null);
  };

  const formateTime = (time) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = time.split(" ")[0];
    const hour = time.split(" ")[1].split(":")[0];
    const minutes = time.split(" ")[1].split(":")[1];

    const now = new Date(date);

    return `${date} (${days[now.getDay()]}) ${hour}:${minutes}`;
  };

  const isParticipant = () => {
    for (let i = 0; i < challenge.participent.length; i++) {
      if (challenge.participent[i].length > 0) {
        setAllowDel(true);
        return;
      }
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    await axios
      .delete(
        `${liveURL}${endPoints.deleteChallenge}?challengeId=${id}`,
        { id: id },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        navigate("/challenges");
      })
      .catch((err) => {
        // console.log(err);
      });
    setDeleting(false);
  };

  const calculateReward = (i) => {
    let noOfParticipants = challenge.participent[i].length;

    const totalAmountOfJoining = noOfParticipants * challenge.details.fee;

    const commission = (totalAmountOfJoining / 100) * 15;

    const reward = totalAmountOfJoining - commission;
    setReward(parseFloat(reward).toFixed(2));
  };

  const markPaid = async () => {
    setPaying(true);
    await axios
      .get(`${liveURL}/${endPoints.markPaid}?contentId=${winner.contentId}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log(res);
        setWinner(false);
        setOpen(false);
        setAlert({
          type: "success",
          msg: "The winner has been marked as paid.",
        });
      })
      .catch((err) => {
        // console.log(err);
        setAlert({ type: "success", msg: "Something went wrong." });
      });
    setPaying(false);
  };

  // console.log(challenge);
  // console.log(open);
  // console.log(winner);
  // console.log(endDateOfSlot);
  // console.log(currentDate);

  useEffect(() => {
    getChallenge();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (challenge) {
      isParticipant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge]);

  const options = {
    onRowClick: (user) => {
      // console.log(challenge[0]);
      const id = user[0];
      // navigate(`/users_section/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: true,
  };

  const columns = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "image",
      label: "User's Profile Image",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="cicle"
              src={image}
              sx={{ width: "3rem", height: "3rem" }}
            />
          );
        },
      },
    },
    {
      name: "name",
      label: "User's Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "User's Email ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "likes",
      label: "Video Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "video",
      label: "Video Link",
      options: {
        customBodyRender: (video) => {
          return (
            <Button
              onClick={() => navigate(`/video/video?=${video}`)}
              variant="contained"
              disableElevation>
              View Video
            </Button>
          );
        },
      },
    },

    {
      name: "userId",
      label: "Action",
      options: {
        customBodyRender: (id) => {
          return (
            <Button
              variant="contained"
              disableElevation
              onClick={() => navigate(`/users_section/${id}`)}>
              View Profile
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (challenge) {
      const endDate = challenge.details.endDate;

      const _endDate = new Date(endDate);

      const now = new Date();

      if (_endDate <= now) {
        // console.log("readOnly");
        setAllowChange(false);
      } else if (_endDate >= now) {
        // console.log("writable");
        setAllowChange(true);
      }
      // console.log(_endDate);
    }
  }, [challenge]);

  // console.log(challenge.details.startDate.split("T")[0]);

  return (
    <Layout title="Challenge Details">
      {challenge ? (
        <div className="challengeDetails__page">
          <Typography variant="h4" sx={{ margin: "1rem 0" }}>
            Challenge Details
          </Typography>
          <div className="challenge__details">
            <TextField
              disabled={!allowChange || allowDel}
              label="Challenge Name"
              value={challenge.details.title}
              size="small"
              onChange={(e) => handleChange("title", e)}
            />
            <TextField
              disabled={!allowChange || allowDel}
              label="Challenge Participation Fee"
              value={challenge.details.fee}
              onChange={(e) => handleChange("fee", e)}
              size="small"
            />
            <TextField
              disabled
              label="Challenge Start Date"
              value={challenge.details.startDate.split("T")[0]}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
            <TextField
              disabled
              label="Challenge End Date"
              value={challenge.details.endDate.split("T")[0]}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </div>
          <div className="form__3">
            <TextField
              sx={{ marginTop: "1rem" }}
              disabled={!allowChange || allowDel}
              label="Challenge Description"
              value={challenge.details.description}
              onChange={(e) => handleChange("description", e)}
              rows="3"
              multiline
              fullWidth
            />
          </div>
          <div className="challengeVideoContainer">
            <Typography
              variant="h5"
              sx={{ margin: "1rem 0", fontWeight: "bold" }}>
              Challenge Video
            </Typography>
            <video
              id="my-player"
              className="chanllengeVideo1"
              controls
              preload="auto">
              <source src={challenge.details.video}></source>
              <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank">
                  supports HTML5 video
                </a>
              </p>
            </video>
          </div>

          {/* {endDateOfSlot && currentDate && (
            <div className="tempDates">
              <Typography>
                endDateTime of selected slot in UTC:{" "}
                {`${endDateOfSlot.split("T")[0]} at ${
                  endDateOfSlot.split("T")[1]
                }`}
              </Typography>
              <Typography>
                current dateTime in UTC:{" "}
                {`${currentDate.split("T")[0]} at ${currentDate.split("T")[1]}`}
              </Typography>
            </div>
          )} */}

          <div className="winnerSlotsContainer">
            <Typography
              variant="h5"
              sx={{ margin: "1rem 0", fontWeight: "bold" }}>
              Winner Slots
            </Typography>
            <div className="winnerSlots">
              {challenge.winnerSlots.map((slot, i) => (
                <div
                  key={i}
                  className="winnerSlot"
                  onClick={() => handleShowWinner(slot, i)}>
                  <Typography>
                    {formateTime(slot.start)} to {formateTime(slot.end)}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="participantsContainer">
            {allowDel && (
              <>
                <Typography
                  variant="h5"
                  sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                  Participants
                </Typography>
                <div className="participants">
                  {challenge.winnerSlots.map((slot, i) => (
                    <>
                      {challenge.participent[i].length > 0 && (
                        <div className="participantSlot" key={i}>
                          <MUIDataTable
                            title={`${formateTime(slot.start)} to ${formateTime(
                              slot.end
                            )}`}
                            data={challenge.participent[i]}
                            columns={columns}
                            options={options}
                          />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </>
            )}
            {!allowDel && (
              <Typography
                variant="h5"
                sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                There are no participants yet for this challenge.
              </Typography>
            )}
          </div>

          <div className="challengeSaveBtn">
            <LoadingButton
              loading={deleting}
              disabled={allowDel}
              onClick={() => setConfirm(true)}
              sx={{ color: "red", marginRight: "1rem", background: "white" }}
              variant="outlined"
              size="large">
              Delete
            </LoadingButton>
            <LoadingButton
              disabled={allowDel}
              loading={loading}
              onClick={() => handleSubmit()}
              variant="contained"
              size="large">
              Save
            </LoadingButton>
          </div>
          <Dialog
            open={open}
            onClose={() => {
              handleClose(false);
            }}>
            <div className="winnerDialog">
              {winner ? (
                <>
                  {winner === "empty" ? (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                        There are no winners yet for this challenge.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="h4">Winner Details</Typography>
                      <div className="winnerDetails">
                        <TextField
                          disabled
                          label="Name"
                          size="small"
                          value={winner.name}
                        />
                        <TextField
                          disabled
                          label="Email"
                          size="small"
                          value={winner.email}
                        />

                        <FormControl fullWidth>
                          <InputLabel htmlFor="outlined-adornment-amount">
                            Reward
                          </InputLabel>
                          <OutlinedInput
                            size="small"
                            disabled
                            id="outlined-adornment-amount"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            label="Reward"
                            value={reward}
                          />
                        </FormControl>
                        {/* <TextField label="Bank Acc#" size="small" /> */}
                      </div>
                      <div className="winnerVideo">
                        <Typography variant="h5">Winner Video</Typography>
                        <video
                          id="my-player"
                          className="chanllengeVideo1"
                          controls
                          preload="auto">
                          <source src={winner.video}></source>
                          <p class="vjs-no-js">
                            To view this video please enable JavaScript, and
                            consider upgrading to a web browser that
                            <a
                              href="https://videojs.com/html5-video-support/"
                              target="_blank">
                              supports HTML5 video
                            </a>
                          </p>
                        </video>
                      </div>
                      <div className="likes">
                        <Typography variant="h4">
                          Likes: {winner.likes}
                        </Typography>
                      </div>
                      <div className="winnerBtn">
                        {winner.paid == 0 ? (
                          <LoadingButton
                            loading={paying}
                            onClick={() => markPaid()}
                            variant="contained"
                            fullWidth>
                            Mark as Paid
                          </LoadingButton>
                        ) : (
                          <LoadingButton disabled variant="contained" fullWidth>
                            Paid
                          </LoadingButton>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </Dialog>
        </div>
      ) : (
        <div className="loaderContainer">
          <div class="loader"></div>
        </div>
      )}

      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <div className="editUser">
          <div className="user__header">
            <Typography variant="h5">
              Are you sure you want to delete this challenge?
            </Typography>
          </div>
          <div className="confirm__form__btn">
            <Button
              onClick={() => setConfirm(false)}
              variant="contained"
              disableElevation>
              Cancel
            </Button>
            <Button
              onClick={() => handleDelete()}
              sx={{ color: "red" }}
              variant="outlined"
              disableElevation>
              Delete
            </Button>
          </div>
        </div>
      </Dialog>
      {alert && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alert ? true : false}
          autoHideDuration={3000}
          onClose={() => setAlert(false)}>
          <Alert
            onClose={() => setAlert(false)}
            severity={alert.type}
            sx={{ width: "fit-content" }}>
            {alert.msg}
          </Alert>
        </Snackbar>
      )}
    </Layout>
  );
}
