import axios from "axios";

export const newBaseURL = "https://tiktok-clone-production.up.railway.app";
export const baseURL = "https://tiktok-clone-production.up.railway.app";

//export const liveURL = "http://63.35.34.209:5000/v1";
export const liveURL = "http://52.73.145.168:5000/v1";
// export const liveURL = "http://localhost:5000/v1";

export const endPoints = {
  getUsers: "/users",
  getUserDetails: "/users/details/", //?userId=id
  changeUserStatus: "/users/deactivate/",
  signIn: "/admin/login",
  markPaid: "challenge/markPaid",

  // challenge
  createChallenge: "/challenge/create",
  getChallengeDetails: "/challenge/details",
  getWinnerDetails: "/challenge/getWinner",
  deleteChallenge: "/challenge",
  updateChallenge: "/challenge",

  // Stats
  getStats: "/challenge/stats",

  // sentNotifications
  sendNotifications: "/users/messageNotification",

  forgotPassword: "/api/forgetPassword",
  changePassword: "/api/changePassword",
  getChallenge: "/challenge/",
  delUsers: "/api/admin/get-users",
  getParticipants: "/api/admin/get-challenge-participants/", // :challengeId?limit=10&page=1
  removeParticipant: "/api/admin/delete-challenge-participants/", // :challengeId/:participantId
  winner: "/api/join-challenge/winner",
  report: "/api/report",
};

export const newEndPoints = {
  reportCount: "/api/report-count",
  challengeCount: "/api/challange-count",
  userCount: "/api/user-count",
};

export var token1 =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY3MTg1Njg0MX0.-6qLk_lGLrIIjbOHK0jpA2s15QLGlG0s_PcZUUoGlxA";

export const getApi = async (url, params = "") => {
  let response;

  let headers = {
    // Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("token")})`,
  };

  // console.log(headers);
  await axios
    .get(`${liveURL}${url}${params}`, {
      headers: headers,
    })
    .then((res) => {
      // console.log(res);
      if (res.data.status === "success") {
        response = {
          ok: true,
          result: res.data.result,
        };
      } else {
        response = {
          ok: false,
          result: {},
        };
      }
    })
    .catch((err) => {
      response = {
        ok: false,
        result: {},
      };
    });

  return response;
};
