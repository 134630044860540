import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// mui
import { ThemeProvider, Typography } from "@mui/material";
import { theme } from "utils/theme";

// pages
import Login from "pages/logIn";
import ChangePassword from "pages/changePassword/index";
import Challenges from "pages/challenges";
import Users from "pages/users/index";
import UserInfo from "pages/users/userInfo";
import Winners from "pages/winners/index";
import Reports from "pages/reports/index";
import Messages from "pages/messsages";
import ChallengeInfo from "pages/challenges/challengeInfo";
import Dashboard from "pages/dashboard";

import Chat from "pages/chats/chat";
import Chats from "pages/chats";
import Stat from "pages/dashboard/stat";
import ReportInfo from "./pages/reports/reportInfo/index";
import { fcm } from "config/firebase";
import { getToken } from "firebase/messaging";
import { getMessaging, onMessage } from "firebase/messaging";
import CustomReports from "pages/customReports";
import { setCookie, removeCookie } from "./utils/cookies";
import Video from "pages/video";
import Report from "./pages/customReports/report/index";
import { Snackbar, Alert } from "@mui/material";
import { PlaylistAddOutlined } from "@mui/icons-material";
import Play from "./pages/play";

export const AppContext = createContext();

export default function App() {
  const [notToken, setNotToken] = useState();
  const [alert, setAlert] = useState(false);
  const [notification, setNotification] = useState(false);

  let headers = {
    // Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(fcm, {
        vapidKey:
          "BPTYWg4wiSLVKeJnNSuBAs5dLBfoStHzfcGLKfckGG0d3l0YYcZ7agF2uQXoG3AyTm26Syxc1fLkO7HV4sCU6LY",
      });
      // console.log("Token Gen", token);
      setNotToken(token);
      // Send this token  to server ( db)
    } else if (permission === "denied") {
      alert("You denied for the notification");
      removeCookie("notToken");
    }
  }

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    // console.log("Message received. ", payload);
    setNotification(payload);
  });

  useEffect(() => {
    if (notification) {
      setAlert({
        type: "info",
        title: notification?.notification?.title,
        msg: notification?.notification?.body,
      });
    } else {
      setAlert(false);
    }
  }, [notification]);

  // console.log(notification);

  // console.log(alert);

  useEffect(() => {
    if (notToken) {
      localStorage.setItem("notToken", notToken);
    }
  }, [notToken]);

  useEffect(() => {
    // Req user for notification permission
    requestPermission();
  }, []);

  return (
    <AppContext.Provider
      value={{
        data: { headers },
        methods: {},
      }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/challenges/:id" element={<ChallengeInfo />} />
            <Route path="/users_section" element={<Users />} />
            <Route path="/users_section/:id" element={<UserInfo />} />
            <Route path="/winners" element={<Winners />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/reports/:id" element={<ReportInfo />} />
            <Route path="/message" element={<Messages />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:type/:days" element={<Stat />} />
            <Route path="/chats" element={<Chats />} />
            <Route path="/chat/:id/:name" element={<Chat />} />
            <Route path="/custom_reports" element={<CustomReports />} />
            <Route path="/custom_reports/:type" element={<Report />} />
            <Route path="/video/:link" element={<Video />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/play" element={<Play />} />
          </Routes>
        </Router>
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              <Typography variant="h6">{alert.title}</Typography>
              <Typography>{alert.msg}</Typography>
            </Alert>
          </Snackbar>
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
}
