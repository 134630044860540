import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  TextField,
  Skeleton,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL, endPoints } from "constants/apiList";
import { LoadingButton } from "@mui/lab";
import { AppContext } from "App";
import { liveURL } from "../../../constants/apiList";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";

function ReportInfo() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const { headers } = useContext(AppContext).data;

  const [report, setReport] = useState();
  const [deletingVideo, setDeletingVideo] = useState(false);
  const [deletingReport, setDeletingReport] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const [confirm1, setConfirm1] = useState(false);

  const getReports = async () => {
    // console.log(headers);
    await axios
      .get(`${liveURL}/challenge/reports?reportId=${id}`, { headers: headers })
      .then((res) => {
        // console.log(res);
        setReport(res.data.result[0]);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const deleteVideo = async () => {
    // console.log("deleting video");
    setDeletingVideo(true);
    await axios
      .delete(`${liveURL}/challenge/content/?contentId=${report.contentId}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log(res);
        navigate("/reports");
      })
      .catch((err) => {
        // console.log(err);
      });
    setDeletingVideo(false);
  };

  const deleteReport = async () => {
    setDeletingReport(true);
    await axios
      .delete(`${liveURL}/challenge/report/?reportId=${id}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log(res);
        navigate("/reports");
      })
      .catch((err) => {
        // console.log(err);
      });
    setDeletingReport(false);
  };

  // console.log("repor", report);

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Reported Video Details">
      <div className="reportPage">
        <Typography variant="h4">Reported Video Details</Typography>
        {report ? (
          <>
            <div className="report__body">
              <div className="contentBy">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Content Uploader
                </Typography>
                <div className="reportInfo">
                  <TextField
                    disabled
                    label="Uploaded by"
                    value={report.videoByName}
                    size="small"
                  />
                  <TextField
                    disabled
                    label="Uploaded on"
                    value={report.uploadedOn.split("T")[0]}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                  />
                  <Link
                    to={`/users_section/${report.videoBy}`}
                    style={{ textDecoration: "none" }}>
                    <Button disableElevation variant="contained">
                      Uploader Details
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="contentBy">
                <div className="challengeVideoContainer">
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Video
                  </Typography>
                  <video
                    id="my-player"
                    className="chanllengeVideo1"
                    controls
                    preload="auto">
                    <source src={report.video}></source>
                    <p class="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank">
                        supports HTML5 video
                      </a>
                    </p>
                  </video>
                </div>
              </div>
              <div className="contentBy">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Reported by
                </Typography>
                <div className="reportInfo">
                  <TextField
                    disabled
                    label="Reported by"
                    value={report.reportedByName}
                    size="small"
                  />
                  <TextField
                    disabled
                    label="Reported on"
                    value={report.reportedOn.split("T")[0]}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                  />
                  <Link
                    to={`/users_section/${report.reportedBy}`}
                    style={{ textDecoration: "none" }}>
                    <Button disableElevation variant="contained">
                      Reporter Details
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="btnss">
                <LoadingButton
                  loading={deletingVideo}
                  onClick={() => setConfirm(true)}
                  variant="contained">
                  Delete Video
                </LoadingButton>
                <LoadingButton
                  loading={deletingReport}
                  onClick={() => setConfirm1(true)}
                  variant="contained">
                  Delete Report
                </LoadingButton>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
        <Dialog open={confirm} onClose={() => setConfirm(false)}>
          <div className="editUser">
            <div className="user__header">
              <Typography variant="h5">
                Are you sure you want to delete the video?
              </Typography>
            </div>
            <div className="confirm__form__btn">
              <Button
                onClick={() => setConfirm(false)}
                variant="contained"
                disableElevation>
                Cancel
              </Button>
              <Button
                onClick={() => deleteVideo()}
                sx={{ color: "red" }}
                variant="outlined"
                disableElevation>
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog open={confirm1} onClose={() => setConfirm1(false)}>
          <div className="editUser">
            <div className="user__header">
              <Typography variant="h5">
                Are you sure you want to delete the report?
              </Typography>
            </div>
            <div className="confirm__form__btn">
              <Button
                onClick={() => setConfirm1(false)}
                variant="contained"
                disableElevation>
                Cancel
              </Button>
              <Button
                onClick={() => deleteReport()}
                sx={{ color: "red" }}
                variant="outlined"
                disableElevation>
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </Layout>
  );
}

export default ReportInfo;
