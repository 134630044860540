import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  TextField,
  Skeleton,
  Snackbar,
  Alert,
  Box,
  ThemeProvider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL, endPoints, getApi, token } from "constants/apiList";
import { LoadingButton } from "@mui/lab";
import { AppContext } from "App";
import { liveURL } from "constants/apiList";
import MUIDataTable from "mui-datatables";
import Loader from "components/loader/loader";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "../../utils/theme";

function Challenges() {
  const navigate = useNavigate();
  const { headers } = useContext(AppContext).data;

  const [challenges, setChallenges] = useState(null);
  const [allChallenges, setAllChallenges] = useState(null);
  const [open, setOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [archivedChallenges, setArchivedChallenges] = useState();
  const [activeChallenges, setActiveChallenges] = useState();

  const now = String(moment().format("")).split("T")[0];
  // console.log(new );

  const [newChallenge, setNewChallenge] = useState({
    title: "",
    fee: "1.50",
    description: "",
    startDate: now,
    video: "",
    thumbnail: "",
  });

  const columns = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "challengeId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "title",
      label: "Challenge Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "fee",
    //   label: "Join fee",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "startDate",
      label: "Challenge Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
    {
      name: "endDate",
      label: "Challenge End Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  const getChallenges = async () => {
    // console.log(headers);

    await axios
      .get(`${liveURL}${endPoints.getChallenge}`)
      .then((res) => {
        // console.log(res);
        const arrOfArchivedChallenges = [];
        for (let i = 0; i < res.data.result.archived.length; i++) {
          arrOfArchivedChallenges.push({
            ...res.data.result.archived[i],
            count: i + 1,
          });
        }
        setArchivedChallenges(arrOfArchivedChallenges);

        const arrOfActiveChallenges = [];
        for (let j = 0; j < res.data.result.active.length; j++) {
          arrOfActiveChallenges.push({
            ...res.data.result.active[j],
            count: j + 1,
          });
        }
        setActiveChallenges(arrOfActiveChallenges);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleChange = (field, e) => {
    if (field === "video") {
      let video = e.target.files[0];
      // console.log("e.target.files", e.target.files);
      setNewChallenge({ ...newChallenge, video: video });
    } else if (field === "thumbnail") {
      let img = e.target.files[0];
      setNewChallenge({ ...newChallenge, thumbnail: img });
    } else if (
      field === "challengeParticipationFee" ||
      field === "challengeReward"
    ) {
      setNewChallenge({ ...newChallenge, [field]: parseFloat(e.target.value) });
    } else if (field == "startDate") {
      const dt = String(e.format()).split("T")[0];
      setNewChallenge({ ...newChallenge, startDate: dt });
    } else {
      setNewChallenge({ ...newChallenge, [field]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      newChallenge.fee.length > 0 &&
      newChallenge.startDate.length > 0 &&
      String(newChallenge.thumbnail).length > 0 &&
      String(newChallenge.video).length > 0 &&
      newChallenge.description.length > 0 &&
      newChallenge.title.length > 0
    ) {
      setUploading(true);

      const formData = new FormData();

      formData.append("fee", newChallenge.fee);
      formData.append("startDate", newChallenge.startDate);
      formData.append("video", newChallenge.video);
      formData.append("description", newChallenge.description);
      formData.append("title", newChallenge.title);
      formData.append("thumbnail", newChallenge.thumbnail);

      await axios
        .post(`${liveURL}${endPoints.createChallenge}`, formData, {
          headers: headers,
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == "fail") {
            setAlert({ type: "error", msg: res.data.message });
          } else {
            setOpen(false);
            setAlert({
              type: "success",
              msg: "Challenge Created Successfully",
            });
            setChallenges(null);
            getChallenges();
            setNewChallenge({
              title: "",
              fee: "1.50",
              description: "",
              startDate: now,
              video: "",
              thumbnail: "",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          setAlert({ type: "error", msg: err.response.data.message });
        });
    } else {
      setAlert({ type: "error", msg: "All fields are mandatory." });
    }
    setUploading(false);
  };

  const handleSearch = (query) => {
    if (!query) {
      setChallenges(allChallenges);
    } else {
      const filteredChallenges = allChallenges.filter((challenge) => {
        const name = challenge.title.toLowerCase();
        return name.includes(query);
      });
      setChallenges(filteredChallenges);
    }
  };

  // console.log(archivedChallenges);
  // console.log(activeChallenges);
  // console.log(newChallenge);
  // console.log(formOfChallenge && formOfChallenge.get("challengeVideo"));

  useEffect(() => {
    getChallenges();
  }, []);

  useEffect(() => {
    const _user = localStorage.getItem("user");

    if (!_user) {
      navigate("/", { replace: true });
    } else {
      const user = JSON.parse(_user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    onRowClick: (challenge) => {
      // console.log(challenge);
      const id = challenge[1];
      navigate(`/challenges/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    filter: false,
    // filterType: "custom",
    rowsPerPageOptions: [10, 50, 100],
  };

  return (
    <Layout title="Challenges">
      <>
        <div className="challenges">
          <div className="challenges__header">
            <Typography variant="h4">Challenges</Typography>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              disableElevation>
              Create Challenge
            </Button>
          </div>
          <div className="tableContainer1">
            {activeChallenges && archivedChallenges ? (
              <div className="mui__tables">
                {activeChallenges.length > 0 ? (
                  <div className="table__mui">
                    <MUIDataTable
                      title={"Active Challenges"}
                      data={activeChallenges}
                      columns={columns}
                      options={options}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                    There are no active challenges available.
                  </Typography>
                )}
                {archivedChallenges.length > 0 ? (
                  <div className="table__mui">
                    <MUIDataTable
                      title={"Archived Challenges"}
                      data={archivedChallenges}
                      columns={columns}
                      options={options}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "2rem", fontWeight: "bold" }}>
                    There are no archived challenges available.
                  </Typography>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <div className="createChallenge">
            <div className="challenge__header">
              <Typography variant="h4">Create New Challenge</Typography>
            </div>
            <div className="creactChallengeForm">
              <TextField
                value={newChallenge.title}
                label="Challenge Name"
                onChange={(e) => handleChange("title", e)}
                size="small"
              />
              {/* <TextField
                label="Challenge Reward"
                onChange={(e) => handleChange("challengeReward", e)}
                type="number"
                size="small"
              /> */}
              <TextField
                value={newChallenge.fee}
                label="Challenge Participation Fee"
                onChange={(e) => handleChange("fee", e)}
                type="number"
                size="small"
              />
              {/* <TextField
                label="Challenge Start Date"
                type="date"
                size="small"
                onChange={(e) => handleChange("startDate", e)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  min: "2023-01-04",
                }}
                fullWidth
              /> */}
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <DatePicker
                    size="small"
                    disablePast
                    label="Challenge Start Date"
                    views={["year", "month", "day"]}
                    value={newChallenge.startDate}
                    onChange={(e) => handleChange("startDate", e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // sx={{ color: "black" }}
                        InputLabelProps={{
                          shrink: true,
                          disableUnderline: true,
                        }}
                        size="small"
                      />
                    )}
                  />
                </ThemeProvider>
              </LocalizationProvider>
              <div className="form_two">
                <div className="form__2">
                  <Typography
                    sx={{
                      width: "100%",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}>
                    Challenge Video:
                  </Typography>
                  <input
                    type="file"
                    onChange={(e) => handleChange("video", e)}
                    name="Video"
                    types={["MP4", "WEBM"]}
                  />
                </div>

                <div className="form__2">
                  <Typography
                    sx={{
                      width: "100%",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}>
                    Challenge Thumbnail:
                  </Typography>
                  <input
                    type="file"
                    onChange={(e) => handleChange("thumbnail", e)}
                    name="Image"
                    types={["PNG", "JPEG", "JPG", "WEBP"]}
                  />
                  {/* {newChallenge.thumbnail && (
                  <div className="thumbnail">
                    <Box
                      component="img"
                      alt="Product Image"
                      src={newChallenge.thumbnail}
                    />
                  </div>
                )} */}
                </div>
              </div>
              {/* <div>
                {newChallenge.challengeVideo && (
                  <video
                    id="my-player"
                    className="winner__videojs"
                    controls
                    preload="auto">
                    <source src={newChallenge.video}></source>
                    <p class="vjs-no-js">
                      To view this video please enable JavaScript, and consider
                      upgrading to a web browser that
                      <a
                        href="https://videojs.com/html5-video-support/"
                        target="_blank">
                        supports HTML5 video
                      </a>
                    </p>
                  </video>
                )}
              </div> */}

              <div className="form__3">
                <TextField
                  label="Challenge Description"
                  onChange={(e) => handleChange("description", e)}
                  rows="3"
                  multiline
                  fullWidth
                />
              </div>
            </div>
            <div className="createChallenge__btn">
              <LoadingButton
                loading={uploading}
                onClick={(e) => handleSubmit(e)}
                variant="contained"
                disableElevation>
                Create Challenge
              </LoadingButton>
            </div>
          </div>
        </Dialog>
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
}

export default Challenges;
