import React, { useEffect } from "react";
import Layout from "fragments/layout/layout";
import { useNavigate } from "react-router-dom";

export default function Messages() {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Messages">
      <h1>Messages</h1>
    </Layout>
  );
}
