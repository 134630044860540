import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useLocation, Link } from "react-router-dom";

import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  TextField,
  Skeleton,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseURL, endPoints } from "constants/apiList";
import { LoadingButton } from "@mui/lab";
import { AppContext } from "App";
import { liveURL } from "../../constants/apiList";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";
import { Avatar } from "@mui/material";

export default function Winners() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [reports, setReports] = useState();
  const [allReports, setAllReports] = useState();
  const [alert, setAlert] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const { headers } = useContext(AppContext).data;

  const getReports = async () => {
    // console.log(headers);
    await axios
      .get(`${liveURL}/challenge/reports`, { headers: headers })
      .then((res) => {
        const arr = [];
        for (let i = 0; i < res.data.result.length; i++) {
          arr.push({
            ...res.data.result[i],
            count: i + 1,
          });
        }
        setReports(arr);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await axios
      .delete(`${baseURL}${endPoints.report}/${id}`)
      .then((res) => {
        // console.log(res);
        setAlert({ type: "success", msg: res.data.message });
        getReports();
      })
      .catch((err) => {
        // console.log(err);
        setAlert({ type: "error", msg: "Something went wrong." });
      });
    setLoading(false);
  };

  const handleSearch = (query) => {
    if (!query) {
      setReports(allReports || []);
    } else {
      const filteredReports = allReports.filter((user) => {
        const postName = user.username.toLowerCase();
        return postName.includes(query);
      });
      setReports(filteredReports);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(reports);
  // console.log(selected);

  const columns = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "reportId",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },

    {
      name: "reportedByImage",
      label: "User's Profile Image",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="cicle"
              src={image}
              sx={{ width: "3rem", height: "3rem" }}
            />
          );
        },
      },
    },
    {
      name: "reportedByName",
      label: "Reported by",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "reportedOn",
      label: "Reported on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1]?.split(":")[0]
              }:${dateTime.split("T")[1]?.split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
    // {
    //   name: "endDate",
    //   label: "End Date",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
  ];

  const options = {
    onRowClick: (report) => {
      // console.log(report);
      const id = report[1];
      navigate(`/reports/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    filter: false,
    rowsPerPageOptions: [10, 50, 100],
  };

  return (
    <Layout title="Reported Videos">
      <>
        <div className="reports_section">
          <div className="reports__header">
            <Typography variant="h4">Reported Videos</Typography>
          </div>
          <div className="reports__body">
            {reports ? (
              <>
                {reports.length > 0 ? (
                  <MUIDataTable
                    title={"Reported Videos"}
                    data={reports}
                    columns={columns}
                    options={options}
                  />
                ) : (
                  <Typography
                    variant="h5"
                    sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                    No data is available.
                  </Typography>
                )}
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={alert ? true : false}
            autoHideDuration={3000}
            onClose={() => setAlert(false)}>
            <Alert
              onClose={() => setAlert(false)}
              severity={alert.type}
              sx={{ width: "fit-content" }}>
              {alert.msg}
            </Alert>
          </Snackbar>
        )}
      </>
    </Layout>
  );
}
