import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import { setCookie } from "utils/cookies";

import {
  Stack,
  FormControlLabel,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton,
  TextField,
  Radio,
  Typography,
  RadioGroup,
  Alert,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { liveURL, endPoints } from "constants/apiList";
import { Helmet } from "react-helmet";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const redirectPath = location.state?.path || "/dashboard";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    setLoading(true);
    setError(false);
    await axios
      .post(`${liveURL}${endPoints.signIn}`, credentials)
      .then((res) => {
        // console.log(res);
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: res.data.result.user[0].username,
            email: res.data.result.user[0].email,
            userId: res.data.result.user[0].userId,
          })
        );
        localStorage.setItem("token", res.data.result.token);
        window.location.reload();
        // navigate(redirectPath, { replace: true });
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
      });
    setLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setError(false);
    setCredentials({ ...credentials, [prop]: event.target.value.trim() });
  };

  const token = localStorage.getItem("notToken");

  useEffect(() => {
    setCredentials({ ...credentials, token: token });
  }, [token]);

  useEffect(() => {
    const _user = localStorage.getItem("user");

    if (_user) {
      navigate("/dashboard", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(credentials);

  return (
    <>
      <Helmet>
        <title>Cashei Admin Panel</title>
      </Helmet>
      <div className="adminLogin">
        <Typography variant="h3" sx={{ margin: "10px" }}>
          Cashei Admin Panel
        </Typography>
        <div className="loginForm">
          <TextField
            onChange={handleChange("email")}
            id="email"
            // label="Email"
            variant="outlined"
            value={credentials.email || ""}
            placeholder="Email"
          />
          <FormControl>
            {/* <InputLabel htmlFor="password">Password</InputLabel> */}
            <OutlinedInput
              placeholder="Password"
              onChange={handleChange("password")}
              id="password"
              value={credentials.password || ""}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className={error ? "error" : "notError"}>
            <Alert severity="error">Email or Password is incorrect!</Alert>
          </div>
          <LoadingButton
            size="large"
            // disabled={!credentials.token}
            loading={loading}
            variant="contained"
            onClick={handleLogin}>
            Log In
          </LoadingButton>
          {/* <Typography variant="caption" sx={{ opacity: "0.7" }}>
            or
          </Typography>
          <div>
            <Link to="/change-password" className="link">
              <Button>Change Password</Button>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Login;
