import React, { useContext } from "react";
import { AppContext } from "App";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Layout from "../../fragments/layout/layout";
import { Typography } from "@mui/material";

export default function Video() {
  const navigate = useNavigate();
  // const params = window.location.link;
  // const { video } = params;
  const { headers } = useContext(AppContext).data;

  const link = window.location.search.split("=")[1];

  return (
    <Layout title="Video">
      <>
        <Typography variant="h4" sx={{ margin: "2rem" }}>
          Video:
        </Typography>
        <div style={{ margin: "1rem 2rem" }}>
          <video
            id="my-player"
            style={{ width: "300px" }}
            controls
            preload="auto">
            <source src={link}></source>
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank">
                supports HTML5 video
              </a>
            </p>
          </video>
        </div>
      </>
    </Layout>
  );
}
