import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import Layout from "fragments/layout/layout";
import { useNavigate, useParams, Link } from "react-router-dom";
import { AppContext } from "App";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";

import {
  Button,
  Dialog,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Switch,
  FormControlLabel,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { baseURL, endPoints, liveURL } from "constants/apiList";
import Loader from "components/loader/loader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";

function UserInfo() {
  const navigate = useNavigate();
  const params = useParams();
  const { headers } = useContext(AppContext).data;
  const { id } = params;

  const [user, setUser] = useState(null);
  const [videos, setVideos] = useState(null);
  const [challenges, setChallenges] = useState(null);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState(false);

  const getUserInfo = async () => {
    await axios
      .get(`${liveURL}${endPoints.getUserDetails}?userId=${parseInt(id)}`, {
        headers: headers,
      })
      .then((res) => {
        // console.log(res);
        setUser(res.data.result.user[0]);
        const _videos = [];
        const _challenges = [];
        for (let i = 0; i < res.data.result.videos.length; i++) {
          _videos.push({ ...res.data.result.videos[i], count: i + 1 });
        }

        for (let i = 0; i < res.data.result.challenges.length; i++) {
          _challenges.push({ ...res.data.result.challenges[i], count: i + 1 });
        }
        setChallenges(_challenges);
        setVideos(_videos);

        // console.log(_videos);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleChangeStatus = async (isDeactivate) => {
    setSaving(true);
    await axios
      .post(
        `${liveURL}${endPoints.changeUserStatus}`,
        {
          userId: id,
          deactivate: isDeactivate == 0 ? 1 : 0,
        },
        { headers: headers }
      )
      .then((res) => {
        // console.log(res);
        setUser({ ...user, deactivate: isDeactivate == 0 ? 1 : 0 });
        setAlert({ type: "success", msg: res.data.message });
      })
      .catch((err) => {
        // console.log(err);
      });
    setSaving(false);
  };

  useEffect(() => {
    if (id) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(user);
  // console.log(videos);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const columns = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "thumbnail",
      label: "Video Thumbnail",
      options: {
        customBodyRender: (image) => {
          return (
            <Avatar
              variant="rounded"
              src={image}
              sx={{ width: "5rem", height: "5rem" }}
            />
          );
        },
      },
    },
    {
      name: "video",
      label: "Video",
      options: {
        filter: true,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "likes",
      label: "Video Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Uploaded on",
      options: {
        filter: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    onRowClick: (video) => {
      // console.log(video);
      const link = video[2];
      navigate(`/video/video?=${link}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    filter: false,
    rowsPerPageOptions: [10, 50, 100],
    // filterType: "custom",
  };

  // new
  const optionsNew = {
    onRowClick: (user) => {
      // console.log(challenge[0]);
      const id = user[0];
      // navigate(`/users_section/${id}`);
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    tableBodyMaxHeight: "500px",
    rowsPerPageOptions: [10, 50, 100],
    filter: true,
  };

  const columnsNew = [
    {
      name: "count",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "title",
      label: "Challenge Name",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "likes",
      label: "Video Likes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userVideo",
      label: "Video Link",
      options: {
        customBodyRender: (video) => {
          return (
            <Button
              onClick={() => navigate(`/video/video?=${video}`)}
              variant="contained"
              disableElevation>
              View Video
            </Button>
          );
        },
      },
    },
    {
      name: "challengeId",
      label: "Challenge Details",
      options: {
        customBodyRender: (id) => {
          return (
            <Button
              variant="contained"
              disableElevation
              onClick={() => navigate(`/challenges/${id}`)}>
              View Challenge
            </Button>
          );
        },
      },
    },
    {
      name: "uploadedAt",
      label: "Uploaded on",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dateTime) => {
          return (
            <Typography>
              {`${dateTime.split("T")[0]} at ${
                dateTime.split("T")[1].split(":")[0]
              }:${dateTime.split("T")[1].split(":")[1]}`}
            </Typography>
          );
        },
      },
    },
  ];

  return (
    <Layout title="User Details">
      <div className="userInfo">
        <Typography variant="h4" sx={{ margin: "1rem 0" }}>
          User Details
        </Typography>
        {user && videos && challenges ? (
          <>
            <Avatar
              src={user.image}
              style={{ width: "6rem", height: "6rem", margin: "10px" }}
            />
            <div className="userInfo__body">
              <TextField label="User's Name" value={user.name} disabled />
              <TextField label="User's Email ID" value={user.email} disabled />
              <TextField
                label="Account Creation Date"
                disabled
                type="date"
                value={user.createdAt.split("T")[0]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <LoadingButton
                disableElevation
                loading={saving}
                variant="contained"
                onClick={() => handleChangeStatus(user.deactivate)}>
                {user.deactivate == "0" ? "Activated" : "Deactivated"}
              </LoadingButton> */}
              <div className="user_status">
                <Typography variant="h6">Account Status</Typography>
                <div className="switch">
                  Inactive
                  <IOSSwitch
                    disabled={saving}
                    onClick={() => handleChangeStatus(user.deactivate)}
                    checked={user.deactivate == "0" ? true : false}
                  />
                  Active
                </div>
              </div>
              <div>
                <Link
                  to={`/chat/${id}/${user.name}?plink=${user.image}`}
                  style={{ textDecoration: "none", width: "100%" }}>
                  <Button variant="contained" fullWidth disableElevation>
                    Chat Now
                  </Button>
                </Link>
              </div>
            </div>
            <Typography
              variant="h5"
              sx={{ margin: "2rem 0 1rem 0", fontWeight: "bold" }}>
              Bank Details
            </Typography>
            <div className="bankDetails">
              <TextField
                label="Bank Name"
                value={user.bankName || ""}
                disabled
              />
              <TextField
                label="Bank Account Number"
                value={user.accountNo || ""}
                disabled
              />
              <TextField
                label="Account Holder Name"
                value={user.holderName || ""}
                disabled
              />
              <TextField
                label="Swift Code"
                value={user.swiftCode || ""}
                disabled
              />
              <TextField label="Address" value={user.address || ""} disabled />
            </div>
            <div style={{ margin: "2rem 0" }}>
              <Typography
                variant="h5"
                sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                Videos
              </Typography>
              <div style={{ margin: "1rem 0" }}>
                <MUIDataTable
                  title={"Videos"}
                  data={videos}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
            {/* ////////////////////////////////// */}
            <div style={{ margin: "2rem 0" }}>
              <Typography
                variant="h5"
                sx={{ margin: "1rem 0", fontWeight: "bold" }}>
                Challenges
              </Typography>
              <div style={{ margin: "1rem 0" }}>
                <MUIDataTable
                  title={"Challenges"}
                  data={challenges}
                  columns={columnsNew}
                  options={optionsNew}
                />
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>

      {alert && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={alert ? true : false}
          autoHideDuration={3000}
          onClose={() => setAlert(false)}>
          <Alert
            onClose={() => setAlert(false)}
            severity={alert.type}
            sx={{ width: "fit-content" }}>
            {alert.msg}
          </Alert>
        </Snackbar>
      )}
    </Layout>
  );
}

export default UserInfo;
