import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import GroupIcon from "@mui/icons-material/Group";
import TaskIcon from "@mui/icons-material/Task";
import ReportIcon from "@mui/icons-material/Report";
import { Skeleton, Typography } from "@mui/material";
import Layout from "fragments/layout/layout";
import { MyResponsivePie } from "components/charts/pie";
import {
  newBaseURL,
  newEndPoints,
  liveURL,
  endPoints,
} from "constants/apiList";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "App";
import Loader from "components/loader/loader";
import { currency } from "constants/currency";

export default function Dashboard() {
  const navigate = useNavigate();
  // const { headers } = useContext(AppContext).data;
  const headers = localStorage.getItem("token");

  const [stats, setStats] = useState();

  const getStats = async () => {
    await axios
      .get(`${liveURL}${endPoints.getStats}`, { headers: headers })
      .then((res) => {
        console.log(res);
        setStats(res.data.result);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Dashboard">
      <h1 style={{ marginLeft: "1rem" }}>
        Hi Admin, Welcome to Cashei Admin Panel
      </h1>
      {stats ? (
        <div className="statsContainer">
          <div className="stat">
            <Typography variant="h4">Users</Typography>
            <div className="counters">
              <Link to={`/dashboard/users/7`} className="counter1">
                <Typography variant="h3">{stats.users.seven.users}</Typography>
                <Typography sx={{ opacity: "0.6" }}>7 Days</Typography>
              </Link>
              <Link to={`/dashboard/users/30`} className="counter2">
                <Typography variant="h3">{stats.users.thirty.users}</Typography>
                <Typography sx={{ opacity: "0.6" }}>30 Days</Typography>
              </Link>
              <Link to={`/users_section`} className="counter3">
                <Typography variant="h3">{stats.users.total.total}</Typography>
                <Typography sx={{ opacity: "0.6" }}>Total</Typography>
              </Link>
            </div>
          </div>
          <div className="stat">
            <Typography variant="h4">Video Contents</Typography>
            <div className="counters">
              <Link to={`/dashboard/content_videos/7`} className="counter1">
                <Typography variant="h3">
                  {stats.content.thirty.content_videos}
                </Typography>
                <Typography sx={{ opacity: "0.6" }}>7 Days</Typography>
              </Link>
              <Link to={`/dashboard/content_videos/30`} className="counter2">
                <Typography variant="h3">
                  {stats.content.thirty.content_videos}
                </Typography>
                <Typography sx={{ opacity: "0.6" }}>30 Days</Typography>
              </Link>
              <Link to={`/dashboard/content_videos/all`} className="counter3">
                <Typography variant="h3">
                  {" "}
                  {stats.content.total.total}
                </Typography>
                <Typography sx={{ opacity: "0.6" }}>Total</Typography>
              </Link>
            </div>
          </div>
          <div className="stat">
            <Typography variant="h4">Revenue</Typography>
            <div className="counters">
              <Link to={`/dashboard/revenue/7`} className="counter1">
                <Typography variant="h3">
                  {currency}
                  {stats.revenue.seven.revenue
                    ? stats.revenue.seven.revenue
                    : 0}
                </Typography>
                <Typography sx={{ opacity: "0.6" }}>7 Days</Typography>
              </Link>
              <Link to={`/dashboard/revenue/30`} className="counter2">
                <Typography variant="h3">
                  {currency}
                  {stats.revenue.thirty.revenue
                    ? stats.revenue.thirty.revenue
                    : 0}
                </Typography>
                <Typography sx={{ opacity: "0.6" }}>30 Days</Typography>
              </Link>
              <Link to={`/dashboard/revenue/all`} className="counter3">
                <Typography variant="h3">
                  {currency}
                  {stats.revenue.total.revenue
                    ? stats.revenue.total.revenue
                    : 0}
                </Typography>

                <Typography sx={{ opacity: "0.6" }}>Total</Typography>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}
