// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsivePie = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    startAngle={-180}
    innerRadius={0.35}
    padAngle={3}
    cornerRadius={3}
    activeOuterRadiusOffset={4}
    colors={{ datum: "data.color" }}
    borderColor={{
      from: "color",
      modifiers: [["darker", "0.7"]],
    }}
    arcLinkLabelsSkipAngle={9}
    arcLinkLabelsTextOffset={12}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsOffset={-1}
    arcLinkLabelsStraightLength={10}
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsRadiusOffset={0.35}
    arcLabelsSkipAngle={34}
    arcLabelsTextColor={{
      from: "color",
      modifiers: [["darker", "1.4"]],
    }}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    legends={[
      {
        anchor: "bottom",
        direction: "column",
        justify: false,
        translateX: -11,
        translateY: 70,
        itemsSpacing: 0,
        itemWidth: 143,
        itemHeight: 29,
        itemTextColor: "#999",
        itemDirection: "left-to-right",
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ]}
  />
);
